.registration-header {
    flex-direction: row;
}

.registration-bar {
    background: #2c2c2c;
    min-height: 75px;
    padding-right: 10px;
}
.logo {
    padding-left: 15px;
}

.style-hr2 {
    min-height: 70px;
    background-color: #4db6ac;
}

.account-info {
    margin-top: 15px;
}