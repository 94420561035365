.page-footer {
    padding-top: 20px;
}
footer.page-footer .footer-copyright{
    background-color: #424242;

    .container {
        color: #fafafa;
    }
}
footer.page-footer{
    padding-top: 5px;
    background-color: #4db6ac;
    position: absolute;
    bottom: 0;
    width: 100%;
    /*Just high enough to be above mui drawer at 1200*/
    z-index: 1201;
}
