.registration-header {
    background-color: #f5f5f5;
}

.registration-container {
    background-color: #fafafa;
}

.placeholder-image {
    width: 350px;
    height: 300px;
    border: 2px solid #263238;
    border-radius: 10px;
}

.style-hr2 {
    min-height: 70px;
    background-color: #0288d1;
}

